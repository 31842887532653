import base from './base';
import { CUSTOMER } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apibank.trusted-document.com',
  customer: CUSTOMER.DEFAULT,
  enablePrivate: false,
  enableSigning: false,
};

export default config;
