export const UTILS = {
  CALENDAR_DATE_FORMAT: 'YYYY-MM-DD',
  API_TIMEZONE: 'Europe/Warsaw',
  TIMEOUT: 'TIMEOUT',
};

export const VALIDATION_ERRORS = {
  REQUIRED: 'Field required',
  FUTURE_DATE: 'Pick future date',
  AFTER_OR_SAME_VALID_SINCE_DATE: 'Pick date after or same as valid from date',
  AFTER_OR_SAME_VALID_UNTIL_DATE: 'Pick date after or same as valid until date',
  TEN_YEARS_AHEAD: 'Pick date at least 10 years from now.',
  MAXIMUM_LENGTH_EXCEEDED: 'Maximum length exceeded',
  MINIMUM_LENGTH_REQUIRED: 'Manimum length required: {length}',
  FILE_SIZE_TOO_LARGE: 'Size of the file is too large.',
  DATE_BETWEEN_1900_2500: 'Select a date from 01.01.1900 to 12.31.2500',
  SAME_AS_PREVIOUS: 'You typed value same as previous',
  INVALID_CODE: 'Invalid code, retry',
  PHONE_INVALID: 'Given phone number is invalid',
  EMAIL_INVALID: 'Email is invalid',
  EMPTY: ' ',
};

export const SETTINGS_CONSTANTS = {
  CUSTOM_LOGO: 'CUSTOM_LOGO',
  PUBLICATOR_NAME: 'PUBLICATOR_NAME',
  PUBLISHER_NAME: 'PUBLISHER_NAME',
  DESCRIPTION: 'DESCRIPTION',
  PUBLISHER_ID: 'PUBLISHER_ID',
  NOTIFICATION_SMS_MESSAGE: 'NOTIFICATION_SMS_MESSAGE',
  NOTIFICATION_EMAIL_MESSAGE: 'NOTIFICATION_EMAIL_MESSAGE',
  NOTIFICATION_EMAIL_TITLE: 'NOTIFICATION_EMAIL_TITLE',
  DOCUMENT_PREVIEW_URL: 'DOCUMENT_PREVIEW_URL',
  SUPPORT_EMAIL: 'SUPPORT_EMAIL',
  PASSWORD_EXPIRATION_TIME: 'PASSWORD_EXPIRATION_TIME',
  RECIPIENT_SOURCE_SYSTEM_NAMES: "RECIPIENT_SOURCE_SYSTEM_NAMES",
};

export const UNKNOWN_ERRORS = {
  UNKNOWN_ERROR: 'UNKNOWN_ERROR',
}

export const PUBLICATION_TYPE = {
  ONE_STEP: 'ONE_STEP',
  TWO_STEP: 'TWO_STEP',
};

export const DOCUMENT_STATUS = {
  UPLOADING: 'UPLOADING',
  CURRENT: 'CURRENT',
  DEPRECATED: 'DEPRECATED',
  RETIRED: 'RETIRED',
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  NOT_EXIST: 'NOT_EXIST',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
};

export const MAP_DOCUMENT_STATUSES = {
  CURRENT: "Published",
  DEPRECATED: "Previous Version",
  PREPARED_TO_SIGN: "Prepared",
  UPLOADING: "Uploading",
  NOT_EXIST: "Invalid",
  NOT_AVAILABLE: "Invalid",
  RETIRED: "Invalid",
};

export const DOCUMENT_PUBLICATION_STATUS = {
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  PUBLISHING_OK: 'PUBLISHING_OK'
};


export enum CUSTOMER {
  DEFAULT = 'DEFAULT',
  TAURON = 'TAURON',
  DIPLOMA = 'DIPLOMA',
  TAURON_AGREEMENT = 'TAURON_AGREEMENT', // Do not use. Its used only for document details
}

export const countryToLanguage = {
  GB: 'en',
  PL: 'pl',
};

export const languageToCountry = {
  en: 'GB',
  pl: 'PL',
};

export const PUBLICATION_MODES = {
  CORRECTED: 'CORRECTED',
  UPDATED: 'UPDATED',
  NEW: 'NEW',
  INCIDENT: 'INCIDENT',
}

export const map_PUBLICATION_MODES = {
  CORRECTED: 'Erratum',
  UPDATED: 'New version',
  NEW: 'New version',
  INCIDENT: 'Incident',
}


export const PUBLICATION_STATUSES = {
  PUBLISHING_OK: 'PUBLISHING_OK',
  PUBLISHING_INITIATED: 'PUBLISHING_INITIATED',
  PREPARED_TO_SIGN: 'PREPARED_TO_SIGN',
  PUBLISHING_ERROR: 'PUBLISHING_ERROR',
}

export const RESEND_NOTIFICATION_MODES = {
  SELECT_TYPE: 'SELECT_TYPE',
  SELECT_GROUP: 'SELECT_GROUP',
  SEARCH_LIST: 'SEARCH_LIST',
  SUMMARY_LIST: 'SUMMARY_LIST',
  EDIT_TYPE_VIEW: 'EDIT_TYPE_VIEW',
  EDIT_EMAIL_NOTIFICATION: 'EDIT_EMAIL_NOTIFICATION',
  EDIT_SMS_NOTIFICATION: 'EDIT_SMS_NOTIFICATION',
  RESEND_PROCESSING: 'RESEND_PROCESSING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
}

export const NOTIFICATION_HISTORY_STATUSES = {
  DELIVERED: "DELIVERED",
  ERROR: "ERROR",
  UNDELIVERED: "UNDELIVERED",
  IN_PROGRESS: "IN_PROGRESS",
}

export const NOTIFICATION_HISTORY_STATUSES_LABELS = {
  DELIVERED: "Status: PENDING",
  ERROR: "Incorrect3",
  UNDELIVERED: "Incorrect3",
  IN_PROGRESS: "Updating",
}

export const NOTIFICATION_HISTORY_FILTERS_OPTIONS = {
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  CLIENT_ID: 'CLIENT_ID',
  STATUS: 'STATUS',
}

export const NOTIFICATION_TYPES = {
  EMAIL: 'EMAIL',
  PHONE: 'PHONE',
}

export const DOCUMENT_RECIPIENTS_GROUPS = {
  ERROR: "ERROR",
  NEW: 'NEW',
}

export const DOCUMENT_RECIPIENTS_FILTERS_OPTIONS = {
  CONTACT_DETAILS: 'CONTACT_DETAILS',
  RECIPIENT_ID: 'RECIPIENT_ID',
  GROUPS: 'GROUPS',
}

export const DOCUMENT_RECIPIENTS_GROUPS_LABELS = {
  ERROR: "Incorrect3",
  NEW: "Status: PENDING",
}

export const USER_STATUSES = {
  SHELL: 'SHELL',
  PENDING: 'PENDING',
  REGISTERED: 'REGISTERED',
  ACCOUNT_TEMPORARY_BLOCKED: 'ACCOUNT_TEMPORARY_BLOCKED',
  PASSWORD_EXPIRED: 'PASSWORD_EXPIRED',
}

export const mapUSER_STATUSES = {
  SHELL: 'Shell',
  PENDING: 'Pending',
  REGISTERED: 'Registered',
  ACCOUNT_TEMPORARY_BLOCKED: 'Blocked',
  PASSWORD_EXPIRED: 'Password-expired',
}

export const USER_STATUS_MESSAGES = {
  SHELL: "Your account has been prepared, please initiate the registration process",
  PENDING: 'Waiting for user to set password',
  REGISTERED: 'The user can make full use of the system',
  ACCOUNT_TEMPORARY_BLOCKED: 'The user has entered the wrong password too many times',
  PASSWORD_EXPIRED: 'User password expired',
}




export const PUBLICATION_ERROR_DETAILS = {
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT-CONTENT-WRONG',
  DOCUMENT_WRONG: 'DOCUMENT-WRONG',
  DOCUMENT_CONTENT_EMPTY: 'DOCUMENT-CONTENT-EMPTY',
  DOCUMENT_DUPLICATED: 'DOCUMENT-DUPLICATED',
  DOCUMENT_EXTENSION_WRONG: 'DOCUMENT-EXTENSION-WRONG',
  DOCUMENT_NOT_PDF: 'DOCUMENT-NOT-PDF',
  DOCUMENT_TOO_LARGE: 'DOCUMENT-TOO-LARGE',
  DOCUMENT_NOT_IDENTICAL: 'DOCUMENT-NOT-IDENTICAL',
  SIGNATURE_DOCUMENT_ALTERED: 'SIGNATURE-DOCUMENT-ALTERED',
  DOCUMENT_NOT_SIGNED: 'DOCUMENT-NOT-SIGNED',
  WRONG_AGREEMENT_TYPE: 'WRONG-AGREEMENT-TYPE',
  WRONG_REGISTRATION_NUMBER: 'WRONG-REGISTRATION-NUMBER',
  WRONG_PAYER_NUMBER: 'WRONG-PAYER-NUMBER',
  EMPTY_CONTACT_DATA: 'EMPTY-CONTACT-DATA',
  WRONG_HTML_FILE: 'WRONG-HTML-FILE',
  CLIENT_CONTACT_DATA_NOT_VALID: 'CLIENT-CONTACT-DATA-NOT-VALID',
  WRONG_FACSIMILE_FILENAME: 'WRONG-FACSIMILE-FILENAME',
  DOCUMENT_PROTECTED: 'DOCUMENT-PROTECTED',
};

export const TAURON_DOCUMENT_TYPE = {
  TARIFF: 'TARIFF',
  AGREEMENT: 'AGREEMENT',
};

export enum DOCUMENT_TYPE {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE',
  REPORT = 'REPORT',
}

export const CONFIG = {
  publicDocuments: 'publicDocuments',
  privateDocuments: 'privateDocuments',
}

export const MAP_DOCUMENT_TYPE_TO_CONFIG = {
  PUBLIC: CONFIG.publicDocuments,
  PRIVATE: CONFIG.privateDocuments,
}

export const AGREEMENT_STATUS = {
  WAITING_FOR_SENDER: 'WAITING-FOR-SENDER',
  WAITING_FOR_RECEIVER: 'WAITING-FOR-RECEIVER',
  PUBLISHED: 'PUBLISHED',
  REJECTED: 'REJECTED',
  ERROR: 'ERROR',
};

export const FILTERS_MAP = {
  category: 'Category',
  documentBlockchainAddress: 'Document blockchain address',
  indexNumber: 'Index number',
  publicationDate: 'Publication date',
  recipient: 'Recipient',
  recipientId: 'Recipient id',
  requestCreationTime: 'Request Issued on',
  requestJobId: 'Request ID',
  requestStatus: 'Request status',
  requestValidityDateRange: 'Request Valid until',
  statusList: 'Status',
  surname: 'Surname',
  title: 'Document title',
  type: 'Document type',
  validFromTo: 'Valid from to',

  // doc-filters
  categoryId: 'Category',
  blockchainAddress: 'Document blockchain address',
  documentType: 'Document type',
  publicationDateRange: 'Publication date from-to',
  publicationStatusList: 'Publication status',
  publishedBy: 'Published by',
  validUntilDateRange: 'Valid from to',
  validSinceDateRange: 'Valid from to',
};

export const FILTERS_MAP_MVP = {
  title: 'Document title',
  category: 'Category',
  statusList: 'Status',
  recipientId: 'Recipient id',
  type: 'Document type',
  publicationDate: 'Publication date',
  validFromTo: 'Valid from to',
  documentBlockchainAddress: 'Document blockchain address',
};
export const REQUEST_STATUS = {
  INITIATED: 'INITIATED',
  REQUESTED: 'REQUESTED',
  REJECTED: 'REJECTED',
  ACCESS_GRANTED: 'ACCESS-GRANTED',
  EXPIRED: 'EXPIRED',
};

export const RETAIN_OPTIONS = {
  YEARS_1: 'YEARS_1',
  YEARS_6: 'YEARS_6',
  YEARS_10: 'YEARS_10',
  YEARS_20: 'YEARS_20',
  YEARS_50: 'YEARS_50',
};

export const RETAIN_OPTIONS_VALUES = {
  YEARS_1: 1,
  YEARS_6: 60,
  YEARS_10: 10,
  YEARS_20: 20,
  YEARS_50: 50,
};

export const retainOptionsList = [
  RETAIN_OPTIONS.YEARS_1,
  RETAIN_OPTIONS.YEARS_6,
  RETAIN_OPTIONS.YEARS_10,
  RETAIN_OPTIONS.YEARS_20,
  RETAIN_OPTIONS.YEARS_50,
];


export const documentPreparationERRORS = {
  // BRUM statusCode
  FORMAT_NOT_SUPPORTED: 'FORMAT_NOT_SUPPORTED',
  DOCUMENT_INFO_MISSING: 'DOCUMENT_INFO_MISSING',
  CATEGORY_NOT_FOUND: 'CATEGORY_NOT_FOUND',
  // BEM statusCode
  CATEGORY_INACTIVE: 'CATEGORY_INACTIVE',
  CATEGORY_WRONG: 'CATEGORY_WRONG',
  CATEGORY_NOT_EXISTING: 'CATEGORY_NOT_EXISTING',
  CATEGORY_PARENT_NOT_ACTIVE: 'CATEGORY_PARENT_NOT_ACTIVE',
  DOCUMENT_CONTENT_EMPTY: 'DOCUMENT_CONTENT_EMPTY',
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT_CONTENT_WRONG',
  DOCUMENT_DUPLICATED: 'DOCUMENT_DUPLICATED',
  DOCUMENT_EXTENSION_WRONG: 'DOCUMENT_EXTENSION_WRONG',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE: 'DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG: 'DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG',
  DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG: 'DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG',
  DOCUMENT_WRONG: 'DOCUMENT_WRONG',
  DOCUMENT_NOT_PDF: 'DOCUMENT_NOT_PDF',
  DOCUMENT_PREVIOUS_VERSION_WRONG: 'DOCUMENT_PREVIOUS_VERSION_WRONG',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING: 'DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED: 'DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED',
  DOCUMENT_PROTECTED: 'DOCUMENT_PROTECTED',
  DOCUMENT_PUBLICATION_REASON_WRONG: 'DOCUMENT_PUBLICATION_REASON_WRONG',
  DOCUMENT_RETENTION_DATE_PAST: 'DOCUMENT_RETENTION_DATE_PAST',
  DOCUMENT_RETENTION_DATE_WRONG: 'DOCUMENT_RETENTION_DATE_WRONG',
  DOCUMENT_TITLE_WRONG: 'DOCUMENT_TITLE_WRONG',
  DOCUMENT_TOO_LARGE: 'DOCUMENT_TOO_LARGE',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
}

export const documentPublicationERRORS = {
  // BEM statusCode
  CATEGORY_INACTIVE: 'CATEGORY_INACTIVE',
  CATEGORY_PARENT_NOT_ACTIVE: 'CATEGORY_PARENT_NOT_ACTIVE',
  DOCUMENT_WRONG: 'DOCUMENT_WRONG',
  DOCUMENT_NOT_IDENTICAL: 'DOCUMENT_NOT_IDENTICAL',
  DOCUMENT_NOT_PDF: 'DOCUMENT_NOT_PDF',
  DOCUMENT_NOT_SIGNED: 'DOCUMENT_NOT_SIGNED',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY: 'DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED: 'DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED',
  DOCUMENT_RETENTION_DATE_PAST: 'DOCUMENT_RETENTION_DATE_PAST',
  SYNC_CONFLICT_SOLUTION_WRONG: 'SYNC_CONFLICT_SOLUTION_WRONG',
  SIGNATURE_DOCUMENT_ALTERED: 'SIGNATURE_DOCUMENT_ALTERED',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
}

export const documentPublicationCkkERRORS = {
  // ckk
  IDENTITY_NOT_FOUND: 'IDENTITY-NOT-FOUND',
  INFRASTRUCTURE_NOT_INITIALIZED: 'INFRASTRUCTURE-NOT-INITIALIZED',
  PUBLISHING_EXCEPTION: 'PUBLISHING-EXCEPTION',
  INVALID_JOB_ID: 'INVALID-JOB-ID',
  CATEGORY_NOT_EXISTING: 'CATEGORY-NOT-EXISTING',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY: 'DOCUMENT-PREVIOUS-VERSION-NOT-LAST-IN-HISTORY',
  DOCUMENT_CONTENT_WRONG: 'DOCUMENT-CONTENT-WRONG',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING: 'DOCUMENT-PREVIOUS-VERSION-NOT-EXISTING',
  DOCUMENT_TOO_LARGE: 'DOCUMENT-TOO-LARGE',
  PUBLISHING_SUBSYSTEM_NOT_READY: 'PUBLISHING-SUBSYSTEM-NOT-READY',
  PUBLISHING_SUBSYSTEM_USER_NOT_LOGGEDIN: 'PUBLISHING-SUBSYSTEM-USER-NOT-LOGGEDIN',
  PUBLISHING_SUBSYSTEM_LOW_DISK_SPACE: 'PUBLISHING-SUBSYSTEM-LOW-DISK-SPACE',
  SEARCHING_FOR_CATEGORIES: 'SEARCHING-FOR-CATEGORIES',
  PUBLISHING_MAX_DOCS_LIMIT_REACHED: 'PUBLISHING-MAX-DOCS-LIMIT-REACHED',
  IDENTITY_ALREADY_REGISTERED: 'IDENTITY-ALREADY-REGISTERED',
  RT_SUBSYSTEM_ERROR: 'RT-SUBSYSTEM-ERROR',
  PUBLISHING_NETWORK_PROBLEM: 'PUBLISHING-NETWORK-PROBLEM',
  FORGETTING_INITIATED: 'FORGETTING-INITIATED',
  FORGETTING_IN_PROGRESS: 'FORGETTING-IN-PROGRESS',
  FORGETTING_OK: 'FORGETTING-OK',
  DOCUMENT_ALREADY_FORGOTTEN: 'DOCUMENT-ALREADY-FORGOTTEN',
  ACCESS_IMPOSSIBLE: 'ACCESS-IMPOSSIBLE',
  DUPLICATED_DOCUMENT: 'DUPLICATED_DOCUMENT',
}

export const NOTIFICATION_HISTORY_ERRORS_STATUSES = {
  // sms status
  REJECTED: 'REJECTED',
  FAILED: 'FAILED',
  UNDELIVERED: 'UNDELIVERED',
  STOP: 'STOP',
  EXPIRED: 'EXPIRED',
  UNKNOWN: 'UNKNOWN',
}

export const mapERRORS = {
  FORMAT_NOT_SUPPORTED: 'Document format not supported, media type should be application/pdf',
  DOCUMENT_INFO_MISSING: 'Document information not found, no json file with document data passed',
  CATEGORY_NOT_FOUND: 'Categories not found',
  CATEGORY_INACTIVE: 'Document category must be active',
  CATEGORY_WRONG: 'The category name must be provided',
  CATEGORY_NOT_EXISTING: 'This category does not exist',
  CATEGORY_ALREADY_EXISTS: 'This category already exists',
  CATEGORY_PARENT_NOT_ACTIVE: 'One or more categories lying on the path of the added category are not active',
  ONE_STEP_PUBLISH_ENABLED: 'One step publication enabled',
  NOT_FOUND: 'Document not found',
  DOCUMENT_CONTENT_EMPTY: 'The document must have the following content',
  DOCUMENT_CONTENT_WRONG: 'Document is damaged',
  DOCUMENT_DUPLICATED: 'An identical document has already been published on the blockchain network',
  DOCUMENT_EXTENSION_WRONG: 'Incorrect file extension',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_TOO_LATE: 'The end date of the document must be prior to or the same as the retention date of the document',
  DOCUMENT_LEGAL_VALIDITY_END_DATE_WRONG: 'Incorrect document end date format',
  DOCUMENT_LEGAL_VALIDITY_START_DATE_WRONG: 'Incorrect document start date format',
  DOCUMENT_WRONG: 'Document not provided',
  DOCUMENT_NOT_PDF: 'Incorrect format of the submitted document. The document must be in pdf format',
  DOCUMENT_PREVIOUS_VERSION_WRONG: 'The address of the previous version of the document must be communicated',
  DOCUMENT_PREVIOUS_VERSION_NOT_EXISTING: 'Previous version of document with specified address not found',
  DOCUMENT_PREVIOUS_VERSION_NOT_REQUIRED: 'The address of the previous version of the document is not required',
  DOCUMENT_PROTECTED: 'Metadata cannot be added to a transmitted document',
  DOCUMENT_PUBLICATION_REASON_WRONG: 'Incorrect reason for publication',
  DOCUMENT_RETENTION_DATE_PAST: 'The retention date shall not be past',
  DOCUMENT_RETENTION_DATE_WRONG: 'Incorrect retention date format',
  DOCUMENT_TITLE_WRONG: 'The title of the document must be forwarded',
  DOCUMENT_TOO_LARGE: 'File size exceeded (x MB)',
  // DOCUMENT_TOO_LARGE	:'File size exceeded',
  DOCUMENT_NOT_IDENTICAL: 'The document transmitted is not the same as the document transmitted in the preparation step',
  DOCUMENT_NOT_SIGNED: 'Document not signed',
  DOCUMENT_PREVIOUS_VERSION_NOT_LAST_IN_HISTORY: 'The address of the previous version of the document must point to the most recent version in the history of the document',
  SYNC_CONFLICT_SOLUTION_WRONG: 'Wrong way to resolve a conflict',
  SIGNATURE_DOCUMENT_ALTERED: 'Document edited after signing',
  IDENTITY_NOT_FOUND: 'Identity not found',
  INFRASTRUCTURE_NOT_INITIALIZED: 'Infrastructure has not been initialised - incorrect status, please contact your administrator',
  PUBLISHING_EXCEPTION: 'No error status found, please contact your administrator',
  INVALID_JOB_ID: 'Invalid jobId',
  PUBLISHING_SUBSYSTEM_NOT_READY: 'The publishing system is not ready, please contact the administrator',
  PUBLISHING_SUBSYSTEM_USER_NOT_LOGGEDIN: 'The user is not logged in to the publishing system, please contact the administrator',
  PUBLISHING_SUBSYSTEM_LOW_DISK_SPACE: 'No disk space available on the publishing system, please contact your administrator',
  SEARCHING_FOR_CATEGORIES: 'Searching for categories',
  PUBLISHING_MAX_DOCS_LIMIT_REACHED: 'The limit on the number of publications has been reached, please contact the administrator',
  IDENTITY_ALREADY_REGISTERED: 'Identity already registered',
  RT_SUBSYSTEM_ERROR: 'Error in Identity Register system, please contact your administrator',
  PUBLISHING_NETWORK_PROBLEM: 'Publishing network error, please contact your administrator',
  FORGETTING_INITIATED: 'The process of forgetting has begun',
  FORGETTING_IN_PROGRESS: 'The process of forgetting is under way',
  FORGETTING_OK: 'Successful forgetting',
  DOCUMENT_ALREADY_FORGOTTEN: 'The document has already been forgotten',
  ACCESS_IMPOSSIBLE: 'Access not possible, please contact your administrator',
  PUBLISHING_ERROR: 'Publishing error',
  ERROR: 'Error',
  DUPLICATED_DOCUMENT: "An identical document has already been published on the blockchain network",

  // sms
  REJECTED: "Message not delivered due to e.g. wrong number, roaming error",
  FAILED: "Sending message failed, please report it to the administrator",
  UNDELIVERED: 'Message not delivered due to e.g. wrong number, roaming error',
  STOP: 'Message sending has been stopped manually',
  EXPIRED: 'Message expired, cannot check status',
  UNKNOWN: 'No report, message status unknown',
  // else
  UNKNOWN_ERROR: 'Unknown error',
  INVALID_IDENTITY: "Invalid student"
}


export const DOCUMENT_ACTIONS = {
  UPDATE: 'UPDATE',
}

export const RECIPIENT_ACTIONS = {
  INSERT_LIST: 'INSERT_LIST',
  UPDATE_LIST: 'UPDATE_LIST',
}


export const RECIPIENT_UPLOAD_ERROR_VALUES = {
  NEEDS_UPDATE: 'NEEDS_UPDATE',
  EMPTY: 'EMPTY',
  DIFFERENT: 'DIFFERENT',
  INVALID: 'INVALID',
  NON_UNIQUE: 'NON_UNIQUE',
}

export const VALIDATION_RECIPIENTS_INTO_FIELDS = {
  EMAIL: 'email',
  ID: 'id',
  PHONE_NUMBER: 'phoneNumber',
}

export const MAP_RECIPIENT_UPLOAD_ERRORS = {
  EMPTY_EMAIL: "Email address missing, please complete",
  EMPTY_ID: "ID missing, please complete",
  EMPTY_SOURCE_SYSTEM: "Source system missing, please complete",
  INVALID_EMAIL: "Invalid e-mail",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  INVALID_SOURCE_SYSTEM: "Invalid source system",
  NEEDS_UPDATE_PHONE_NUMBER: "Recipient's phone number will be updated",
  DIFFERENT_EMAIL: "Recipient with the given ID already exists and has a different email address",
}



export const mapDOCUMENT_ACTIONS_ERROR = {
  UPDATE: 'An error occurred while trying to update document',
  PREPARE: 'An error occurred while trying to prepare document',
  PUBLISH: 'An error occurred while trying to publish document',
  DELETE: 'An error occurred while trying to delete document',
}

export const mapDOCUMENT_ACTIONS_SUCCESS = {
  UPDATE: 'Update successful',
}


export const PASSWORD_VALIDATION = {
  PASSWORD_LENGTH: 'PASSWORD_LENGTH',
  PASSWORD_COMPLEXITY_GROUPS: 'PASSWORD_COMPLEXITY_GROUPS',
  UNIQUE_PASSWORDS: 'UNIQUE_PASSWORDS',
  HOURS_TO_CHANGE_PASSWORD: 'HOURS_TO_CHANGE_PASSWORD',
}

export const PASSWORD_VALIDATION_ERRORS = {
  PASSWORDS_VARY: "PASSWORDS_VARY",
  PASSWORD_IDENTICAL: 'PASSWORD_IDENTICAL',
}

export const MAP_PASSWORD_VALIDATION_ERRORS = {
  PASSWORDS_VARY: "Passwords vary",
  INCORRECT_PREVIOUS_PASSWORD: "Password is incorrect",
  PASSWORD_IDENTICAL: "Password must be different from the current one",
}

export const PASSWORD_VALIDATION_REASONS = {
  PASSWORD_TOO_SHORT: "PASSWORD_TOO_SHORT",
  PASSWORD_NO_UNIQUE_CHARACTERS: "PASSWORD_NO_UNIQUE_CHARACTERS",
  PASSWORD_PREVIOUSLY_USED: "PASSWORD_PREVIOUSLY_USED",
  PASSWORD_CHANGED_TOO_OFTEN: "PASSWORD_CHANGED_TOO_OFTEN",
  INCORRECT_PREVIOUS_PASSWORD: "INCORRECT_PREVIOUS_PASSWORD",
}

export const MAP_PASSWORD_VALIDATION_REASONS = {
  PASSWORD_TOO_SHORT: "PASSWORD_LENGTH",
  PASSWORD_NO_UNIQUE_CHARACTERS: "PASSWORD_COMPLEXITY_GROUPS",
  PASSWORD_PREVIOUSLY_USED: "UNIQUE_PASSWORDS",
  PASSWORD_CHANGED_TOO_OFTEN: "HOURS_TO_CHANGE_PASSWORD",
  INCORRECT_PREVIOUS_PASSWORD: "INCORRECT_PREVIOUS_PASSWORD",
}

export const MAP_PASSWORD_VALIDATION_TO_REASONS = {
  PASSWORD_LENGTH: "PASSWORD_TOO_SHORT",
  PASSWORD_COMPLEXITY_GROUPS: "PASSWORD_NO_UNIQUE_CHARACTERS",
  UNIQUE_PASSWORDS: "PASSWORD_PREVIOUSLY_USED",
  HOURS_TO_CHANGE_PASSWORD: "PASSWORD_CHANGED_TOO_OFTEN",
  INCORRECT_PREVIOUS_PASSWORD: "INCORRECT_PREVIOUS_PASSWORD",
}


export const MAP_PASSWORD_VALIDATION = {
  PASSWORD_LENGTH: "Password must contain at least {n} characters",
  PASSWORD_COMPLEXITY_GROUPS: "Password must contain at least {n} of the following 4 groups: lower case letters, capital letters, digits, special characters",
  UNIQUE_PASSWORDS: "Password must not be the same as the previous {n}",
  HOURS_TO_CHANGE_PASSWORD: "Password can only be changed once in {n} hours",
}

export const VALIDATION_ERROR_TYPES = {
  WHITESPACE_FORBIDDEN: 'WHITESPACE_FORBIDDEN',
  CHANGED_TOO_OFTEN: "CHANGED_TOO_OFTEN",
  PREVIOUSLY_USED: "PREVIOUSLY_USED",
  TOO_SHORT: "TOO_SHORT",
  NO_UNIQUE_CHARACTERS: "NO_UNIQUE_CHARACTERS",
  INCORRECT_PREVIOUS: "INCORRECT_PREVIOUS",
  IDENTICAL: 'IDENTICAL',
  EMPTY: 'EMPTY',
  VARY: "VARY",
}

export const MAP_VALIDATION_ERROR_TYPES = {
  WHITESPACE_FORBIDDEN: "Password must not contain white characters (e.g. spaces)",
  CHANGED_TOO_OFTEN: "Password can only be changed once in {n} hours",
  PREVIOUSLY_USED: "Password must not be the same as the previous {n}",
  TOO_SHORT: "Password must contain at least {n} characters",
  NO_UNIQUE_CHARACTERS: "Password must contain at least {n} of the following 4 groups: lower case letters, capital letters, digits, special characters",
  INCORRECT_PREVIOUS: "Password is incorrect",
  IDENTICAL: "Password must be different from the current one",
  EMPTY: 'Ppassword cannot be empty',
  VARY: "Passwords vary",
}

export const MAP_VALIDATION_ERROR_TO_SETTINGS_KEYS = {
  CHANGED_TOO_OFTEN: "HOURS_TO_CHANGE_PASSWORD",
  PREVIOUSLY_USED: "UNIQUE_PASSWORDS",
  TOO_SHORT: "PASSWORD_LENGTH",
  NO_UNIQUE_CHARACTERS: "PASSWORD_COMPLEXITY_GROUPS",
}

export const password_role_based_info_list = [
  VALIDATION_ERROR_TYPES.TOO_SHORT,
  VALIDATION_ERROR_TYPES.NO_UNIQUE_CHARACTERS,
  VALIDATION_ERROR_TYPES.PREVIOUSLY_USED,
]

export const password_other_info_list = [
  VALIDATION_ERROR_TYPES.CHANGED_TOO_OFTEN,
]


export const UserRoles = {
  UNLOGGED: 'UNLOGGED',
  VISITOR: 'VISITOR',
  AGENT: 'AGENT',
  ADMIN: 'ADMIN',
  DPI: 'DPI',
}

export const USER_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
}

export const VALIDATION_USER_ERRORS_NAMES = {
  USERNAME: 'USERNAME',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  PHONE_NUMBER: 'PHONE_NUMBER',
  EMAIL: 'EMAIL',
  DEPARTMENT: 'DEPARTMENT',
  IS_ACTIVE: 'IS_ACTIVE',
  NOTIFY: 'NOTIFY',
  ROLE: 'ROLE',
}

export const mapVALIDATION_USER_ERRORS_NAMES = {
  USERNAME: 'username',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PHONE_NUMBER: 'phoneNumber',
  EMAIL: 'email',
  DEPARTMENT: 'department',
  IS_ACTIVE: 'isActive',
  NOTIFY: 'notify',
  ROLE: 'role',
  DOCUMENT_NUMBER: 'documentNumber',
}

export const mapVALIDATION_USER_FIELDS_INTO_ERRORS = {
  username: VALIDATION_USER_ERRORS_NAMES.USERNAME,
  firstName: VALIDATION_USER_ERRORS_NAMES.FIRST_NAME,
  lastName: VALIDATION_USER_ERRORS_NAMES.LAST_NAME,
  phoneNumber: VALIDATION_USER_ERRORS_NAMES.PHONE_NUMBER,
  email: VALIDATION_USER_ERRORS_NAMES.EMAIL,
  department: VALIDATION_USER_ERRORS_NAMES.DEPARTMENT,
  isActive: VALIDATION_USER_ERRORS_NAMES.IS_ACTIVE,
  notify: VALIDATION_USER_ERRORS_NAMES.NOTIFY,
  role: VALIDATION_USER_ERRORS_NAMES.ROLE,
}

export const mapVALIDATION_USER_ERRORS = {
  NON_UNIQUE_USERNAME: "Username already used",
  NON_UNIQUE_PHONE_NUMBER: "Phone number already used",
  NON_UNIQUE_EMAIL: "Email address already used",
  NON_UNIQUE_DOCUMENT_NUMBER: "Document number already used",

  EMPTY_USERNAME: "Please enter username",
  EMPTY_FIRST_NAME: "Please enter first name",
  EMPTY_LAST_NAME: "Please enter last name",
  EMPTY_PHONE_NUMBER: "Please enter phone number",
  EMPTY_EMAIL: "Please enter e-mail",
  EMPTY_DOCUMENT_NUMBER: "Please enter document number",

  INVALID_DOCUMENT_NUMBER: "Invalid document number",
  INVALID_EMAIL: "Invalid e-mail",
  INVALID_PHONE_NUMBER: "Invalid phone number",
  INVALID_PESEL: "Invalid PESEL",
  INVALID_INDEX: "Invalid index",
}

export const PASSWORD_FORM_MODES = {
  PASSWORD_SET: 'PASSWORD_SET',
  PASSWORD_CHANGE: 'PASSWORD_CHANGE',
  ADMINISTRATIVE_PASSWORD_CHANGE: 'ADMINISTRATIVE_PASSWORD_CHANGE',
}

export enum ORDER_METHOD {
  ASC = 'ASCENDING',
  DESC = 'DESCENDING'
}

export enum DOCUMENT_ORDER_BY {
  publicationDate = 'PUBLICATION_DATE',
  validUntil = 'LEGAL_VALIDITY_FINISH_DATE',
  validSince = 'LEGAL_VALIDITY_START_DATE',
  category = 'CATEGORY',
  title = 'TITLE',
  identity = 'SURNAME',
  documentPublicationStatus = 'PUBLICATION_STATUS'
}

export enum CATEGORY_STATUSES {
  SUCCESS = 'SUCCESS',
  ADDING_ERROR = 'ADDING_ERROR',
  IN_PROGRESS = 'IN_PROGRESS'
}


export const VALIDATION_LOGIN_ERRORS = {
  FORBIDDEN: 'FORBIDDEN',
  ACCOUNT_INACTIVE: 'ACCOUNT_INACTIVE',
  ACCOUNT_TEMPORARY_BLOCKED: 'ACCOUNT_TEMPORARY_BLOCKED',
}

export const MAP_VALIDATION_LOGIN_ERRORS = {
  FORBIDDEN: "Incorrect login or password, please try again",
  ACCOUNT_INACTIVE: "Your account is inactive, please contact the administrator",
  ACCOUNT_TEMPORARY_BLOCKED: "Account will be unlocked in {n} min.",
}

export enum PUBLICATION_FIELD_NAMES {
  PUBLISHED_BY = 'publishedBy',
  TITLE = 'title',
  CATEGORY = 'category',
  VALID_SINCE = 'validSince',
  VALID_UNTIL = 'validUntil',
  IDENTITY = 'identity',
  FILE = 'file',
  VERSION = 'version'
}

export const TAURON_VERIFICATION_CODE_STATUSES = {
  FOREIGN_NUMBERS_BLOCKED: "Block shipping to international numbers",
  BLACKLISTED_PHONE_NUMBER: "Blacklisted phone number",
  IN_PROCESS: "Could not get SMS status for unspecified reason",
  NOT_PROCESSED: "SMS with specified id already exists, has not been processed",
  PHONE_NUMBER_TOO_LONG: "Phone number is too long",
  PHONE_NUMBER_TOO_SHORT: "Phone number is too short",
  WRONG_OPERATOR_PREFIX: "Operator prefix is invalid",
  WRONG_COUNTRY_PREFIX: "Country prefix is invalid",
  WRONG_PHONE_NUMBER: "There are invalid characters in the phone number",
  CONTENT_TOO_LONG: "The content of the SMS is longer than 160 characters",
  FORBIDDEN_CHARACTERS: "SMS contains non-ASCII characters",
  IN_PROGRESS: "SMS has not been processed for an unspecified reason",
  SENDING_STARTED_SUCCESSFULLY: "SMS waiting to be sent to operator's SMSC (>5min)",
  DELIVERED_TO_OPERATOR: "SMS was delivered to operator's SMSC - confirmation of SMS receipt by operator has arrived",
  NOT_DELIVERED_TO_OPERATOR: "SMS has not been delivered to the operator's SMSC",
  WAITING_TO_DELIVER: "The SMS is waiting in the operator's SMSC for delivery to the phone",
  DELIVERED: "SMS was delivered to the phone",
  NOT_DELIVERED: "SMS has not been delivered by the operator to the phone",
  EXPIRED: "SMS has not been delivered to the operator due to timeout, e.g. because the phone was out of range for too long",
}

