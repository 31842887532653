import base from './base';
import { CUSTOMER, PUBLICATION_TYPE } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apidiplomapublisher.bc.sygnity.pl',
  enablePrivate: true,
  enableSigning: false,
  customer: CUSTOMER.DIPLOMA,
  publicationType: PUBLICATION_TYPE.TWO_STEP,
};

export default config;
