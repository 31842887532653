const localTheme = {
  fontFamilyBase: "'Montserrat', Arial, Helvetica, sans-serif",
  fontSizeBase: '1rem',
  fontSizeSm: '0.875rem',
  fontSizeLg: '1.25rem',
  fontSize12: '0.75rem',
  fontSize11: '0.625rem',
  fontSize18: '1.125rem',
  fontSize24: '1.5rem',
  fontSize32: '2rem',
  fontSize40: '2.5rem',
  fontSize48: '3rem',
  fontWeightLight: 300,
  fontWeightBase: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 950,

  palette: {
    grayLightest: '#fafafa',
    grayLighter: '#cad5dd',
    grayLightest50: 'rgba(250, 250, 250, 0.5)',
    grayLighter50: 'rgba(202, 213, 221, 0.5)',
    grayLight: '#abc4c6',
    gray: '#58595B',
    grayDark: '#333333',
    grayBack: '#a3a3a3',

    error2: '#fc5151',
    error: '#ad113b',

    success2: '#00b769',

    primary: '#00adff',
    primaryLight: '#cbeeff',
    primaryLightest: '#e5f6ff',
    primaryDark: '#007aff',

    secondary: '#60d170',
    secondaryLight: '#e8f3fd',
    secondaryDark: '#007aff',
    secondaryGrey: '#acbbc7',

    danger: '#f5c6cb',
    dangerLight: '#f8d7da',
    dangerDark: '#721c24',
    warning: '#ffeeba',
    warningLight: '#fff3cd',
    warningDark: '#856404',
    success: '#c3e6cb',
    successLight: '#d4edda',
    successDark: '#155724',
    info: '#bee5eb',
    infoLight: '#d1ecf1',
    infoDark: '#0c5460',

    iconColor: '#5d5d5d',
    iconHoverColor: '#006181',
    borderHoverColor: '#006181',

    billonGreen: '#00b769',
    billonBlue: '#2e75b5',
    billonOrange: '#ff9200',
  },

  borderRadius: '5px',
  boxShadow: '0 2px 5px rgba(0,0,0,.1)',

  bodyColor: '#011627',
  bodyBackground: '#fff',

  linkHoverDecoration: 'none',
  linkDecoration: 'none',

  navbar: {
    zIndex: 150,
    boxShadow: '0 1px 5px rgba(0,0,0,0.2)',
    padding: '0 3rem',
    background: '#fff',
  },
  navbarBrand: {
    marginRight: '1rem',
    padding: 0,
  },

  transitionBase: 'all .2s ease-in-out',

  caretWidth: '0.25rem',
  caretVerticalAlign: '0.175rem',
  caretSpacing: '0.3rem',

  spacerBase: '1rem',
  spacerLarge: '2rem',
  spacerLargest: '3rem',

  dropdown: {
    menu: {
      zIndex: 1000,
      minWidth: '10rem',
    },
  },

  grid: {
    columns: 12,
  },
  breakpoints: {
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
  },

  disabledOpacity: 0.6,
  disabledCursor: 'not-allowed',
  disabledBackground: '#eaeef0',
};

localTheme.linkColor = localTheme.palette.primary;
localTheme.linkHoverColor = localTheme.palette.primaryDark;
localTheme.textMuted = localTheme.palette.gray;
localTheme.dropdown.borderRadius = localTheme.borderRadius;
localTheme.dropdown.boxShadow = localTheme.boxShadow;
localTheme.buttonColor = localTheme.palette.bodyColor;
localTheme.buttonHoverColor = localTheme.palette.grayDark;
localTheme.primaryButtonColor = '#fff';
localTheme.primaryButtonHoverColor = '#fff';
localTheme.secondaryButtonColor = '#fff';
localTheme.secondaryButtonHoverColor = '#fff';
localTheme.paginationColor = localTheme.palette.primaryLight;
localTheme.paginationHoverColor = localTheme.palette.primary;
localTheme.paginationActiveColor = localTheme.palette.primary;

export default localTheme;
