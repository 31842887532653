import base from './base';
import { CUSTOMER, PUBLICATION_TYPE } from '../constraints';

const config = {
  ...base,
  backendUrl: 'https://apipublicatormvp.trusted-document.com',
  enablePrivate: true,
  customer: CUSTOMER.DEFAULT,
  language: 'pl',
  publicationType: PUBLICATION_TYPE.TWO_STEP,
};

export default config;
