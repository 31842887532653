import base from './base';
import { CUSTOMER } from '../constraints';

const config = {
  ...base,
  theme: {
    ...base.theme,
    palette: {
      ...base.theme.palette,
      primary: '#004d8d',
    },
    linkColor: '#004d8d',
  },
  backendUrl: 'https://apidiplomapublication.trusted-document.com',
  enablePrivate: true,
  enableSigning: false,
  customer: CUSTOMER.DIPLOMA,
};

export default config;
