import { theme } from 'billon-ui';
import localTheme from './localTheme';
import { PUBLICATION_TYPE } from '../constraints';
import merge from 'lodash.merge';

export default {
  limit: 10,
  limit_5: 5,
  theme: {
    ...theme,
    fontWeightExtraBold: localTheme.fontWeightExtraBold,
    palette: {
      ...merge({ ...theme.palette }, { ...localTheme.palette }),
      green: '#60D170',
      yellow: '#F4AD21',
      red: '#cd4657',
    },
  },
  availableLanguages: ['en', 'pl'],
  language: sessionStorage.getItem('language')
    ? sessionStorage.getItem('language')
    : 'pl',
  publicationType: PUBLICATION_TYPE.ONE_STEP,
};
